<template>
  <div class="">
    <PageHeader :items="items" />
    <div class="row">
      <div
        v-if="$can(`unit.list`)"
        class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
      >
        <router-link to="/unit">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'userNIcon'" />
            <p class="setting-card-text">{{ $t("Organisational Units") }}</p>
          </div>
        </router-link>
      </div>
      <!-- <div
        v-if="$can(`user.list`)"
        class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
      >
        <router-link to="/user">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'userNIcon'" />
            <p class="setting-card-text">{{ $t("User") }}</p>
          </div>
        </router-link>
      </div> -->

      <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/roles">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'RolesNIcon'" />
            <p class="setting-card-text">{{ $t("Roles") }}</p>
          </div>
        </router-link>
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3">
        <router-link to="/permissions">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'permissionNIcon'" />
            <p class="setting-card-text">{{ $t("Permissions") }}</p>
          </div>
        </router-link>
      </div> -->
      <!-- <div
        v-if="$can(`api-keys.list`)"
        class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3"
      >
        <router-link to="/api-keys">
          <div class="setting-card-container">
            <ElementIcon v-bind:icon="'apiKeyNIcon'" />
            <p class="setting-card-text">{{ $t("Api Keys") }}</p>
          </div>
        </router-link>
      </div> -->
    </div>
  </div>
</template>

<script>
import ElementIcon from "@/components/elements/Icon.vue";
import { BTabs, BTab } from "bootstrap-vue";
import PageHeader from "@/components/PageHeader.vue";
export default {
  components: {
    BTabs,
    BTab,
    ElementIcon,
    PageHeader,
  },
  computed: {
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          active: true,
        },
      ];
    },
  },
};
</script>
<style lang="scss">
// .setting-card-container:hover {
//   background-color: #38414a;
// }

// .setting-card-container:hover i {
//     color: white;
// }

// .setting-card-container:hover .setting-card-text {
//     color: white;
// }
</style>
